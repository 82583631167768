<template>
  <div class="ele-activity-edit-form">
    <el-form size="mini" label-position="left" label-width="10%">
      <el-form-item label="活动ID">
        <el-input v-model="form.activityId" clearable />
      </el-form-item>
      <el-form-item label="标题">
        <el-input v-model="form.title" clearable />
      </el-form-item>
      <el-form-item label="描述">
        <el-input v-model="form.description" clearable />
      </el-form-item>
      <el-form-item label="佣金比例">
        <el-input v-model="form.commissionRate" clearable>
          <template slot="append">%</template>
        </el-input>
      </el-form-item>
      <el-form-item label="活动日期">
        <el-date-picker v-model="form.startDate" value-format="yyyy-MM-dd" />
        <span> ~ </span>
        <el-date-picker v-model="form.endDate" value-format="yyyy-MM-dd" />
      </el-form-item>
      <el-form-item label="显示顺序">
        <el-input v-model="form.showOrder" clearable />
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "ele-activity-edit-form",
  props: {
    action: {
        type: String,
        default: 'add'
    },
    id: {
        type: Number
    }
  },
  data() {
    return {
      form: {},
    };
  },
  methods: {
    getData() {
        return this.form;
    },
    opened() {
      this.loadData();
    },
    async loadData() {
        if (!this.id || this.id <= 0) {
            return;
        }
        let data = await this.$http.doApi('fw-ele-activity-get', {id: this.id});
        this.form = data;
    }
  },
};
</script>

<style lang="less">
.ele-activity-edit-form {
  padding: 1rem;
}
</style>